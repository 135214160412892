import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const SidebarRestore = ({data}) => {
    return (
        <div className="sidebar">
            <a href="/donate/" className='sidebarCta'>
                <div className="sidebarCtaTitle">Donate Today</div>
                <StaticImage src="../images/partners/partner-2.jpg" alt="Donate" class="sidebarImage" />
            </a>
            <a href="/get-involved/volunteer/" className='sidebarCta'>
                <div className="sidebarCtaTitle">Volunteer</div>
                <StaticImage src="../images/partners/partner-7.jpg" alt="Volunteer" class="sidebarImage" />
            </a>
            <a href="/about/contact/" className='sidebarCta'>
                <div className="sidebarCtaTitle">Sign Up For Our Newsletter</div>
                <StaticImage src="../images/24th-build/IMG_9779.jpg" alt="Newsletter" class="sidebarImage" />
            </a>
            <div className="iframeWrapper">
                <iframe title="Facebook Feed" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fnorthwoods.restore&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId" width="340" height="500" scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </div>
        </div>
    )
}
export default SidebarRestore