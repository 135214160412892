import * as React from "react"
import { Seo } from "../components/seo"
import Layout from '../components/layout'
import SidebarRestore from '../components/sidebar-restore'
import { StaticImage } from "gatsby-plugin-image"
import { FaFacebook } from 'react-icons/fa'

const RestorePage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../images/hero-images/hero-image-3.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>ReStore</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
					<h2>Shopping at the ReStore supports safe, decent, affordable housing.</h2>
					<p>
						Our mission is to be a fundraiser for Habitat for Humanity in Oneida and Vilas counties.
						All proceeds support local Habitat for Humanity home construction projects.
					</p>
					<p>
						The ReStore helps the community by recycling gently used items, keeping them from the landfill, and providing a variety of affordable, home-improvement supplies.
					</p>
					<p><a href="https://www.facebook.com/northwoods.restore/" className="button button-secondary" target="_blank" rel="noopener noreferrer"><FaFacebook /> Check out our Facebook page!</a></p>
					<h2>ReStore and Donation Hours</h2>
					<p>Monday - Friday: 10am - 4pm</p>
					<p>Saturdays: 10am - 3pm</p>
					<h3>Address</h3>
					<p>908 Lincoln St<br />Rhinelander, WI 54501</p>
					<h3>Phone Number</h3>
					<p><a href="tel: (715) 420- 2301">(715) 420- 2301</a></p>
					<h4>What items does the ReStore accept?</h4>
					<p>
						We take new and gently used building materials, appliances, cabinets, hardware, tools, and furniture.
						All items should be in good, clean, and usable condition.
					</p>
					<h4>Where can I bring my donations?</h4>
					<p>
						All donations can be conveniently dropped off at our building located at 908 Lincoln St.
					</p>
					<h4>What if I need my items picked up?</h4>
					<p>
						It's as easy as clicking the Schedule Pickup button below. 
						You can also email the ReStore at <a href="mailto:h4hnwwi.pickitup@gmail.com">h4hnwwi.pickitup@gmail.com</a> or 
						call <a href="tel: (715) 420-2301">(715) 420-2301</a>.
					</p>
					<p>
						<a className="button" href="/restore/request-pick-up/">Schedule Pickup</a>
					</p>
					<h4>Is there anything the ReStore doesn't accept?</h4>
					<p>At this time, we cannot accept:</p>
					<ul>
						<li>Box Style TVs</li>
						<li>Pianos or Organs</li>
						<li>Mattresses or bedding</li>
						<li>Infant furniture</li>
						<li>Fluorescent light fixtures or bulbs</li>
						<li>Pressboard furniture</li>
						<li>Partial cans of paint</li>
						<li>Exercise equipment</li>
						<li>Entertainment centers</li>
						<li>Large office desks</li>
						<li>Household chemicals</li>
						<li>Medical equipment</li>
						<li>Unframed or broken mirrors</li>
						<li>Anything that is damaged, dirty, outdated, or not likely to resell</li>
					</ul>
					<p>
						<strong>All donation pick-ups must be ready for pickup on the ground level, garage, or driveway of your home or business.</strong>
					</p>
					<iframe width="560" height="315" src="https://www.youtube.com/embed/pVvMoWszkg8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					<div className="spacer"></div>
					<h2>Paint Selection</h2>
					<StaticImage src="../images/paint-selection.jpg" alt="Paint Selection" />
				</main>
				<SidebarRestore />
			</div>
		</section>
	</Layout>
  )
}
export default RestorePage

export const Head = () => (
	<Seo
        title="Restore | Habitat For Humanity Northwoods Wisconsin"
    />
)
